.userName {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #ffffff;
    font-weight: bold;
}

.notifications:after {
    background-color: #c96e39;
    border-radius: 50%;
    content: "";
    position: absolute;
    right: 0px;
    height: 5px;
    width: 5px;
    top: 6px;
}

.notification-block + .notification-block {
    margin-top: 32px;
}

.notification-block::before {
    content: "";
    position: absolute;
    top: 45px;
    height: 100%;
    left: 23px;
    width: 1px;
    border-left: 1px dashed #474761;
}

.notification-block:last-child::before {
    border: none;
}

.noti-icon {
    width: 45px;
    height: 45px;
    border-radius: 50px;
    background-color: #323248;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
}

.noti-content .text-white {
    max-width: 60%;
}
